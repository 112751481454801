<template>
  <web-app
    ref="templateRef"
    :position="position"
    :backgroundColor="backgroundColor"
    :navItemTheme="navItemTheme"
    :logoTheme="logoTheme"
    :showSeekButton="showSeekButton"
    v-if="isNavigatorClient"
  ></web-app>
  <web-pc
    ref="templateRef"
    :position="position"
    :backgroundColor="backgroundColor"
    :navItemTheme="navItemTheme"
    :logoTheme="logoTheme"
    :showSeekButton="showSeekButton"
    v-else
  ></web-pc>
</template>

<script setup>
import webPc from "./client/webPc.vue";
import webApp from "./client/webApp.vue";

import { computed, ref, getCurrentInstance, onUnmounted } from "vue";

const props = defineProps({
  position: {
    type: String,
    default: "sticky",
  },
  backgroundColor: {
    type: String,
    default: "#ffffffab",
  },
  navItemTheme: {
    type: String,
    default: "dark",
  },
  // logo 主体
  logoTheme: {
    type: String,
    default: "dark",
  },
  // 显示咨询
  showSeekButton: {
    type: Boolean,
    default: true,
  },
});

const { proxy } = getCurrentInstance();
const { mainStore, dataStore } = proxy.$usePiniaModule();

// ref
const templateRef = ref();

const isNavigatorClient = computed(() => proxy.$isNavigatorClient());
// const isNavigatorClient = computed(() => true);

// 属性方法
const setHeaderProps = (result) => {
  setTimeout(() => {
    templateRef.value.setHeaderProps(result);
  });
};


defineExpose({
  setHeaderProps,
});
</script>
