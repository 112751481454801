<template>
  <div
    class="header-container user-select-none pc-wrap"
    :style="{ backgroundColor: useBackgroundColor, position: usePosition }"
  >
    <div class="wrap">
      <div class="wrap-left">
        <img
          class="logo-label"
          :src="useLogoIcon"
          alt=""
          @click="onChangeNavbar(navbarOptions[0])"
        />
      </div>

      <div class="wrap-right">
        <!-- 选项卡 -->
        <div :class="`wrap-tab-box ${useNavItemTheme}`">
          <div
            class="tab-item fontRegular"
            v-for="(item, index) in navbarOptions"
            :key="'item' + index"
            @click="onChangeNavbar(item)"
            :class="{ 'is-active': item.value === chooseNavbarValue }"
          >
            {{ item.label }}
          </div>

          <!-- 移动标识 -->
          <div class="move-tab-bar" :style="{ left: currMoveBarPos }"></div>
        </div>

        <!-- 咨询按钮 -->
        <div
          class="seek-advice fontRegular"
          v-if="useShowSeekButton"
          @click="jumpFormScrollTop"
        >
          免费咨询
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted, watch } from "vue";

const emits = defineEmits(["onChangeNavbar"]);

// 组件属性
const props = defineProps({
  position: {
    type: String,
    default: "sticky",
  },
  backgroundColor: {
    type: String,
    default: "#ffffffab",
  },
  navItemTheme: {
    type: String,
    default: "dark",
  },
  // logo 主体
  logoTheme: {
    type: String,
    default: "dark",
  },
  // 显示咨询
  showSeekButton: {
    type: Boolean,
    default: true,
  },
});

// 自定义设置的props
const customProps = ref(null);

// 使用的props
const useProps = computed(() => customProps.value || props);

const { proxy } = getCurrentInstance();

const { mainStore, dataStore } = proxy.$usePiniaModule();

// 图标
// logo
const logoDarkIcon = require("@/assets/images/logo-label-dark.png");
const logoLightIcon = require("@/assets/images/logo-label-light.png");
const useLogoIcon = computed(() =>
  useProps.value.logoTheme === "dark" ? logoDarkIcon : logoLightIcon
);

const useNavItemTheme = computed(() => useProps.value.navItemTheme);
const useBackgroundColor = computed(() => useProps.value.backgroundColor);
const usePosition = computed(() => useProps.value.position);
const useShowSeekButton = computed(() => useProps.value.showSeekButton);

// 导航栏
const navbarOptions = ref([
  {
    label: "首页",
    value: 0,
    path: "/",
    moveBarPos: "0.5%",
  },
  {
    label: "服务",
    value: 1,
    path: "/serve",
    moveBarPos: "25.5%",
  },
  {
    label: "案例中心",
    value: 2,
    path: "/case",
    moveBarPos: "54.0%",
  },
  {
    label: "关于我们",
    value: 3,
    path: "/about",
    moveBarPos: "79.5%",
  },
]);
// 选中的导航标识
const chooseNavbarValue = ref("/");
// 移动标识位置
const currMoveBarPos = computed(() => {
  const { moveBarPos = "0.5%" } =
    navbarOptions.value.find((d) => d.value == chooseNavbarValue.value) || {};
  return moveBarPos;
});
// 监听点击 导航
const onChangeNavbar = (item) => {
  const { value, path } = item;
  const call = () => {
    chooseNavbarValue.value = value;
    proxy.$router.push(path);
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    });
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(
    function(params){
      document
        .getElementsByClassName("wrap-tab-box")[0]
        .getElementsByClassName("tab-item")
        [params.value].click();
    },
    {
      value,
    }
  );
  call();
  emits("onChangeNavbar", item);
};

// 当前路由信息
const currRouteInfo = computed(() => proxy.$route);
// 路由路径
const routePath = computed(() => currRouteInfo.value.path);
watch(
  () => routePath.value,
  (nVal) => {
    init();
  },
  {
    deep: true,
  }
);

// 免费咨询 定位底部表单
const jumpFormScrollTop = (e) => {
  const call = () => {
    const scrollTop =
      document.getElementsByClassName("footer-container")[0].offsetTop;
      window.scrollTo({ top: scrollTop });
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(function(){
    document
      .getElementsByClassName("header-container")[0]
      .getElementsByClassName("seek-advice")[0]
      .click();
  });
  call();
};

// 设置header属性
const setHeaderProps = (propsParam) => {
  customProps.value = propsParam;
};

const init = () => {
  // 判断路由 赋值指定导航项
  const route = currRouteInfo.value;
  const { path, name } = route;
  const someItem = navbarOptions.value.find((d) => d.path == path) || {};

  // 案例详情
  if (name == "listCase") {
    chooseNavbarValue.value = navbarOptions.value[2].value;
  } else if (name == "newsInformation" || name == "newsInformationDetail") {
    chooseNavbarValue.value = navbarOptions.value[0].value;
  } else {
    chooseNavbarValue.value = someItem.value;
  }
};

onMounted(() => {
  init();
});

defineExpose({
  setHeaderProps,
  onChangeNavbar,
});
</script>

<style lang="scss" scoped>
.header-container {
  &.pc-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    z-index: 999;
    background-color: white;
    box-shadow: 1px 2px 7px 1px #0000000a;
    backdrop-filter: blur(6px);

    .wrap {
      // padding: 15px 0;
      height: 70px;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: auto 1fr;
      justify-content: space-between;
      justify-items: end;
      align-items: center;
      &-left {
        width: 185px;
        height: 37px;
        cursor: pointer;
        .logo-label {
          // width: 185px;
          // height: 37px;
          // cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
      &-right {
        display: grid;
        grid-template-columns: 1fr auto;
        position: relative;
        max-width: 620px;
        width: 100%;
        .wrap-tab-box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: center;
          position: relative;
          padding: 8px 0;

          &.dark {
            .tab-item {
              color: rgba(113, 117, 145, 1);
              &:hover {
                color: var(--c-black-color);
              }

              &.is-active {
                color: var(--c-black-color) !important;
              }
            }

            .move-tab-bar {
              background-color: var(--c-black-color);
            }
          }
          &.light {
            .tab-item {
              color: white;
              &:hover {
                color: white;
              }

              &.is-active {
                color: white !important;
              }
            }

            .move-tab-bar {
              background-color: white;
            }
          }

          .tab-item {
            font-size: 18px;
            transition: color 500ms;
            cursor: pointer;

            &:hover {
              color: var(--c-black-color);
            }

            &.is-active {
              font-weight: bold;
            }
          }
          .move-tab-bar {
            position: absolute;
            bottom: -15px;
            width: 30px;
            height: 3px;
            border-radius: 10px;
            transition: 300ms;
          }
        }

        .seek-advice {
          padding: 7px 14px 9px;
          font-size: 18px;
          background-color: rgba(10, 65, 255, 1);
          color: white;
          cursor: pointer;
          border-radius: 6px;
          transition: background 300ms;

          &:active {
            background-color: #0a41ffcf;
          }
        }
      }
    }
  }
}
</style>
