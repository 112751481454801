<template>
  <div class="footer-container pc-wrap">
    <div class="wrap-left">
      <img class="logo-label" :src="logoLabelIcon" alt="" />
    </div>

    <div class="wrap-content">
      <div class="content-box">
        <div class="wrap-box-1 wrap-box">
          <div class="warp-item-1 warp-item">
            <div class="label fontBold">联系热线：</div>
            <p class="text fontRegular">177 2247 6866</p>
          </div>
          <div class="warp-item-2 warp-item">
            <div class="label fontBold">邮箱：</div>
            <p class="text fontRegular">cjx@cloudeffective.cn</p>
          </div>
          <!-- <div class="warp-item-3 warp-item">
            <div class="label">友情链接：</div>
            <p class="text">安服优</p>
          </div> -->
        </div>
        <div class="wrap-box-2 wrap-box">
          <div class="warp-item-1 warp-item">
            <div class="label fontBold">软件外包：</div>
            <div class="show-title">
              <div
                class="text fontRegular"
                v-for="(item, index) in serverInfos"
                :key="'text' + index"
              >
                <p class="bar fontRegular" v-if="index"></p>
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-box-3 wrap-box">
          <div class="warp-item-1 warp-item">
            <p class="left fontRegular">
              Copyright © 2022-2023 深圳市云效科技有限公司 All Rights Reserved.
            </p>
            <p class="right fontRegular" @click="jumpFilings">
              粤ICP备2023002768号
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrap-right">
      <div class="qrcode-image">
        <img :src="qrcodeImage" alt="" />
      </div>
      <p class="label fontRegular">扫一扫添加微信在线咨询</p>
    </div>
  </div>
</template>

<script setup>
// 图标

import { computed, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const { dataStore } = proxy.$usePiniaModule();

// logo
const logoLabelIcon = require("@/assets/images/logo-label-dark.png");
// 二维码
const qrcodeImage = require("@/assets/images/call-qrocde-border.png");

// 软件外包服务信息
const serverInfos = [
  "APP定制",
  "定制",
  "小程序开发",
  "公众号开发",
  "商城定制",
  "企业定制",
  "WEB定制",
  "网页定制",
  "物联网定制",
];

// 跳转备案
const jumpFilings = () => {
  const call = () => {
    window.open("https://beian.miit.gov.cn");
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(call);
  call();
};
</script>

<style lang="scss" scoped>
.footer-container {
  &.pc-wrap {
    display: grid;
    grid-template-columns: auto 1fr auto;
    max-width: 1200px;
    margin: auto;
    padding-top: 30px;
    background-color: white;
    .wrap {
      &-left {
        .logo-label {
          width: 185px;
          height: 37px;
        }
      }

      &-content {
        .content-box {
          width: fit-content;
          margin: auto;
          font-size: 13px;
          .wrap-box {
            &-1 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
            &-2 {
              margin-top: 20px;
              .warp-item {
                &-1 {
                  .show-title {
                    display: flex;

                    .text {
                      display: flex;
                      align-items: center;
                      height: 25px;
                      transition: opacity 300ms;

                      &:hover {
                        opacity: 0.6;
                      }
                    }

                    .bar {
                      width: 1px;
                      height: 13px;
                      background-color: rgba(102, 102, 102, 1);
                      margin: 0 10px;
                    }
                  }
                }
              }
            }

            &-3 {
              width: fit-content;
              margin: auto;
              margin-top: 30px;
              .warp-item {
                &-1 {
                  color: rgba(153, 153, 153, 1);
                  font-size: 14px;
                  .left {
                    margin-right: 20px;
                  }
                  .right {
                    cursor: pointer;
                  }
                }
              }
            }

            .warp-item {
              display: flex;
              align-items: center;
              .label {
                font-weight: bold;
              }
              .text {
                transition: opacity 300ms;

                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }
        }
      }

      &-right {
        .qrcode-image {
          display: flex;
          justify-content: center;
          img {
            width: 110px;
            height: 110px;
          }
        }
        .label {
          font-size: 12px;
          color: rgba(153, 153, 153, 1);
          text-align: center;
          line-height: 35px;
        }
      }
    }
  }
}
</style>
