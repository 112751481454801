<template>
  <div
    class="layout-container"
    :class="`${isNavigatorClient ? 'navigator-client' : 'pc-client'}`"
  >
    <!-- 案例模板展示 -->
    <Header
      ref="headerRef"
      v-if="showCaseTemplateNavbar"
      :showSeekButton="showFreeSeekButton"
      :backgroundColor="
        currCaseTemplateParam.headerStyle.backgroundColor || 'white'
      "
      :navItemTheme="currCaseTemplateParam.headerStyle.navItemTheme || 'dark'"
      :logoTheme="currCaseTemplateParam.headerStyle.logoTheme || 'dark'"
      :position="currCaseTemplateParam.headerStyle.position || 'sticky'"
    ></Header>

    <!-- 常规主页展示 -->
    <div class="layout-container__header" v-else>
      <Header
        v-show="showHeaderNavbar"
        :showSeekButton="showFreeSeekButton"
        ref="headerRef"
      ></Header>
    </div>

    <div class="layout-container__content">
      <router-view :key="routePath + routeParams.stemp"></router-view>
    </div>

    <div class="layout-container__footer">
      <Footer v-show="showDownFooter"></Footer>
    </div>
  </div>
</template>

<script setup>
import Header from "./components/header/index.vue";
import Footer from "./components/footer/index.vue";
import * as caseTemplates from "../list-case/template/data";
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
  nextTick,
} from "vue";
import actionjs from "./action.js";

const { proxy } = getCurrentInstance();

const { initMouseOperateFun } = actionjs.apply(proxy);

const { mainStore, dataStore } = proxy.$usePiniaModule();

// ref
const headerRef = ref();

// 判断客户端
const isNavigatorClient = computed(() => proxy.$isNavigatorClient());

// 当前路由信息
const currRouteInfo = computed(() => proxy.$route);
// 路由携带参数
const routeParams = computed(() => currRouteInfo.value.query);
// 路由路径
const routePath = computed(() => currRouteInfo.value.path);

// 显示免费咨询按钮
const showFreeSeekButton = computed(() => {
  // 展示的页面
  const showPaths = ["/", "/case", "/about", "/serve"];

  return showPaths.includes(routePath.value);
});

// 当前模板参数
const currCaseTemplateParam = computed(
  () => caseTemplates[routeParams.value.name]
);
// 展示模板导航
const showCaseTemplateNavbar = computed(() => !!currCaseTemplateParam.value);

// 初始 window信息
const initWindowInfo = (res) => {
  const { innerWidth, innerHeight, outerWidth, outerHeight } = res;

  mainStore.windowInnerWidth = innerWidth;
  mainStore.windowInnerHeight = innerHeight;
  mainStore.windowOuterWidth = outerWidth;
  mainStore.windowOuterHeight = outerHeight;
};

// 顶部导航栏显示
const showHeaderNavbar = computed(() => mainStore.showHeaderNavbar);
// 底部脚本信息显示
const showDownFooter = computed(() => mainStore.showDownFooter);

// 设置header属性
const setHeaderProps = (result) => {
  setTimeout(() => {
    headerRef.value.setHeaderProps(result);
  });
};
// 统计访问量
const addStatisticsVisits = async () => {
  try {
    await proxy.$storeDispatch("fetchAddStatisticsVisits");
  } catch (error) {
    console.log(error);
  }
};

/* 监听鼠标操作数据 */
watch(
  () => dataStore.getGlobalStoreMouseRecord,
  async (nVal) => {
    const pageSize = 300;
    const current = nVal.length % pageSize;
    if (!current && !!nVal.length) {
      const data = proxy.$removeDecontextAttr(
        dataStore.globalStoreMouseOperationRecord
      );
      proxy.$addMousePtsSux(data);

      //  重置
      dataStore.globalStoreMouseOperationRecord = [];
    }
  },
  {
    deep: true,
  }
);

// 初始当前轨迹
const initMouseOperateRecord = () => {
  dataStore.setGlobalClickRecord(
    function (params) {
      const { value } = params;
      const currHref = this.$route.href;
      const currQuery = this.$route.query;
      const urlstr = this.$paramToStr({ ...currQuery, isInitPath: 1 });

      // 路径值
      if (currHref != value) {
        const [href] = value.split("?");
        window.location.href = href + "?" + urlstr;
      }
    },
    {
      value: proxy.$route.href,
      isInitPath: true,
    }
  );
};

// ********************************************************************************
// 模拟鼠标轨迹 START
// ********************************************************************************

/* 鼠标操作记录 */
//开始回溯
const startBacktracking = (data) => {
  const barItemBefore = document.getElementsByClassName("bar-round")[0];
  if (!!barItemBefore) {
    barItemBefore.remove();
  }

  nextTick(async () => {
    // 生成一个标点
    const barItem = document.createElement("div");
    barItem.classList.add("bar-round");
    barItem.style.opacity = 1;

    document.body.appendChild(barItem);

    await basedOnRecordedDataBacktracking(barItem, data);
  });
};
// 清除回溯
const clearBacktracking = () => {
  clearMoveBacktrackDiv();
  clearBacktrackData();
};
// 清除回溯数据
const clearBacktrackData = () => {};
// 清除移动轨迹div
const clearMoveBacktrackDiv = () => {
  const bars = document.getElementsByClassName("move-bar");
  if (!bars.length) {
    return;
  }
  bars[0].remove();
  clearMoveBacktrackDiv();
};

// 根据记录数据进行回溯操作
const basedOnRecordedDataBacktracking = async (barDom, data) => {
  for (var i = 0; i < data.length; i++) {
    const re = data[i];
    const { type } = re;
    switch (type) {
      case "mousemove":
        await handleMousemove(barDom, re);
        break;
      case "scroll":
        await handleMouseScroll(re);
        break;
      case "mousedown":
        await handleMousedown(barDom, re);
        break;
      case "mouseup":
        await handleMouseup(barDom, re);
        break;
    }
  }

  barDom.remove();
  clearBacktrackData();
};

// 操作移动类型
const handleMousemove = async (barDom, props) => {
  const { moveX, moveY, showWidth, showHeight } = props;

  await executeRecordAnimation(props, (resolve) => {
    const { clientWidth, clientHeight } = document.body;
    // 比例
    // const scaleX = clientWidth / showWidth;
    const scale = 0.61;
    barDom.style.top = moveY + "px";
    barDom.style.left = moveX + "px";
    resolve();
  });
};
// 操作滚动类型
const handleMouseScroll = async (props) => {
  const { scrollTop } = props;
  const scale = 0.39;
  await executeRecordAnimation(props, (resolve) => {
    window.scrollTo({ top: scrollTop });
    resolve();
  });
};
// 操作点击摁住类型
const handleMousedown = async (barDom, props) => {
  await executeRecordAnimation(props, (resolve) => {
    barDom.classList.add("is-click");
    resolve();
  });
};
// 操作点击松开类型
const handleMouseup = async (barDom, props) => {
  const { isInitPath } = routeParams.value;
  await executeRecordAnimation(props, (resolve) => {
    const { callback, params = {} } = props;

    // 拦截 初始路路径
    if (isInitPath == 1 && !!params.isInitPath) {
      resolve();
      return;
    }

    barDom.classList.remove("is-click");
    // console.log(routeParams.value)
    if (!!callback) {
      // 字符串转函数
      const myAnonymous = new Function("params", `return ${callback}`);
      const myCall = myAnonymous();
      myCall.apply(proxy, [params]);
    }
    resolve();
  });
};

// 动画 记录执行器
const executeRecordAnimation = (props, callback) => {
  const { delay } = props;
  return new Promise((resolve) => {
    const cancelSetTimeoutId = setTimeout(async () => {
      await callback(resolve);
      clearTimeout(cancelSetTimeoutId);
    }, delay);
  });
};

// ********************************************************************************
// 模拟鼠标轨迹 START
// ********************************************************************************

// ********************************************************************************
// 查询操作 START
// ********************************************************************************

// 获取路径轨迹数据集合
const getPathTraceDataList = async () => {
  try {
    const { code, ip } = routeParams.value;
    if (!ip) {
      return;
    }
    const params = {
      code,
      ip,
    };
    const res = await proxy.$storeDispatch("fetchGetPathTraceDataList", params);
    const result = res.result;

    const data = result.reduce((acc, curr) => {
      const list = JSON.parse(curr);

      return acc.concat(list);
    }, []);

    setTimeout(() => {
      startBacktracking(data);
    });
  } catch (error) {}
};

// ********************************************************************************
// 查询操作 END
// ********************************************************************************

const init = () => {
  // 初始操作区域的事件监听
  initMouseOperateFun();
  initMouseOperateRecord();

  // 生成环境
  if (process.env.NODE_ENV === "production") {
    if (window) {
      window.console.log = function () {};
      window.console.warn = function () {};
      window.console.error = function () {};
    }
  }

  // 路径操作
  if (!!routeParams.value.ip) {
    getPathTraceDataList();
  } else {
    addStatisticsVisits();
  }
  initWindowInfo(window);
};

// 注入方法
provide("setHeaderProps", setHeaderProps);

onMounted(() => {
  init();

  window.onresize = (e) => {
    initWindowInfo(e.target);
  };
});
</script>

<style lang="scss">
.layout-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;

  &__header {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}
</style>
