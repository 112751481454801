<template>
  <div
    class="header-container user-select-none mobile-wrap"
    :style="{ backgroundColor: useBackgroundColor, position: usePosition }"
  >
    <div class="wrap-left">
      <img
        class="logo-label"
        :src="useLogoIcon"
        alt=""
        @click="onChangeNavbar(navbarOptions[0])"
      />
    </div>

    <div class="wrap-right">
      <!-- 咨询按钮 -->
      <div
        class="seek-advice fontRegular"
        v-if="useShowSeekButton"
        @click="jumpFormScrollTop"
      >
        免费咨询
      </div>

      <!-- 菜单 -->
      <div class="menu-box">
        <el-dropdown
          ref="dropdownRef"
          trigger="click"
          popper-class="btn-popper"
          :hide-on-click="true"
          @command="onCommand"
        >
          <img :src="useMenuIcon" @click="handleDropdown" alt="" />

          <template #dropdown>
            <el-dropdown-menu>
              <div class="line" :style="{ top: currMoveBarPos }"></div>
              <el-dropdown-item
                v-for="(item, index) in navbarOptions"
                :key="index + 'dropdown'"
                @click="onChangeNavbar(item)"
                :class="{ 'is-active': item.value === chooseNavbarValue }"
              >
                <div class="text fontRegular">{{ item.label }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  getCurrentInstance,
  nextTick,
  onMounted,
  watch,
} from "vue";

const emits = defineEmits(["onChangeNavbar"]);

const props = defineProps({
  position: {
    type: String,
    default: "sticky",
  },
  backgroundColor: {
    type: String,
    default: "#ffffffab",
  },
  navItemColor: {
    type: String,
    default: "rgba(113, 117, 145, 1)",
  },
  // logo 主体
  logoTheme: {
    type: String,
    default: "dark",
  },
  // 显示咨询
  showSeekButton: {
    type: Boolean,
    default: true,
  },
});

const { proxy } = getCurrentInstance();

const { mainStore, dataStore } = proxy.$usePiniaModule();

// 自定义设置的props
const customProps = ref(null);

// 使用的props
const useProps = computed(() => customProps.value || props);

// ref
const dropdownRef = ref();

// 图标
// logo
const logoDarkIcon = require("@/assets/images/logo-label-dark.png");
const logoLightIcon = require("@/assets/images/logo-label-light.png");
const useLogoIcon = computed(() =>
  useProps.value.logoTheme === "dark" ? logoDarkIcon : logoLightIcon
);
// 菜单
const menuIconDark = require("@/assets/images/menu-icon-dark.png");
const menuIconLight = require("@/assets/images/menu-icon-light.png");
const useMenuIcon = computed(() =>
  useProps.value.logoTheme === "dark" ? menuIconDark : menuIconLight
);

const useBackgroundColor = computed(() => useProps.value.backgroundColor);
const usePosition = computed(() => useProps.value.position);
const useShowSeekButton = computed(() => useProps.value.showSeekButton);

// 导航栏
const navbarOptions = ref([
  {
    label: "首页",
    value: 0,
    path: "/",
    moveBarPos: "10.5%",
  },
  {
    label: "服务",
    value: 1,
    path: "/serve",
    moveBarPos: "35.5%",
  },
  {
    label: "案例中心",
    value: 2,
    path: "/case",
    moveBarPos: "60.5%",
  },
  {
    label: "关于我们",
    value: 3,
    path: "/about",
    moveBarPos: "85.5%",
  },
]);
// 选中的导航标识
const chooseNavbarValue = ref("/");
// 移动标识位置
const currMoveBarPos = computed(() => {
  const { moveBarPos = "10.5%" } =
    navbarOptions.value.find((d) => d.value == chooseNavbarValue.value) || {};
  return moveBarPos;
});
// 监听点击 导航
const onChangeNavbar = (item) => {
  const { value, path } = item;
  const call = () => {
    chooseNavbarValue.value = value;
    proxy.$router.push(path);

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    });
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(
    function (params) {
      document
        .getElementsByClassName("el-dropdown-menu")[0]
        .getElementsByClassName("el-dropdown-menu__item")
        [params.value].click();
    },
    {
      value,
    }
  );

  call();
  emits("onChangeNavbar", item);
};
// 操作dropdown
const handleDropdown = () => {
  // 记录鼠标操作
  dataStore.setGlobalClickRecord(function () {
    document.getElementsByClassName("el-tooltip__trigger")[0].click();
  });
};

// 当前路由信息
const currRouteInfo = computed(() => proxy.$route);
// 路由路径
const routePath = computed(() => currRouteInfo.value.path);
watch(
  () => routePath.value,
  (nVal) => {
    init();
  },
  {
    deep: true,
  }
);

// 点击菜单项触发的事件回调
const onCommand = (e) => {
  nextTick(() => {
    handleClose();
  });
};

// 关闭下拉
const handleClose = () => {
  // console.log("回调 ");
  dropdownRef.value.handleClose();
};

// 免费咨询 定位底部表单
const jumpFormScrollTop = () => {
  const call = () => {
    const scrollTop =
      document.getElementsByClassName("footer-container")[0].offsetTop;
      window.scrollTo({ top: scrollTop });
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(function () {
    document
      .getElementsByClassName("header-container")[0]
      .getElementsByClassName("seek-advice")[0]
      .click();
  });
  call();
};

// 设置header属性
const setHeaderProps = (propsParam) => {
  customProps.value = propsParam;
};

const init = () => {
  // 判断路由 赋值指定导航项
  const route = currRouteInfo.value;
  const { path, name } = route;
  const someItem = navbarOptions.value.find((d) => d.path == path) || {};

  // 设置悬浮样式
  document.getElementsByClassName('btn-popper')[0].parentElement.style.top = 0
  document.getElementsByClassName('btn-popper')[0].parentElement.style.position = 'fixed'

  // 案例详情
  if (name == "listCase") {
    chooseNavbarValue.value = navbarOptions.value[2].value;
  } else {
    chooseNavbarValue.value = someItem.value;
  }
};

onMounted(() => {
  init();
});

defineExpose({
  setHeaderProps,
});
</script>

<style lang="scss" scoped>
.header-container {
  &.mobile-wrap {
    background-color: #ffffffab;
    padding: 12px 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 1px 2px 7px 1px #0000000a;
    backdrop-filter: blur(6px);

    .wrap {
      &-left {
        display: flex;
        align-items: center;
        .logo-label {
          width: 106px;
          height: 21px;
        }
      }
      &-right {
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .seek-advice {
          padding: 8px 12px;
          font-size: 14px;
          background-color: rgba(10, 65, 255, 1);
          color: white;
          border-radius: 6px;
          transition: background 300ms;

          &:active {
            background-color: #0a41ffcf;
          }
        }

        .menu-box {
          width: 28px;
          height: 20px;
          margin-left: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.btn-popper {
  inset: 67.3333px auto auto 218px !important;
  border-radius: 10px !important;
  --el-dropdown-menuItem-hover-fill: var(--el-color-white);
  --el-dropdown-menuItem-hover-color: var(--el-color-white);
  .el-dropdown-menu {
    padding: 0 20px !important;
    border-radius: 10px !important;

    .el-dropdown-menu__item {
      box-sizing: border-box;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
      padding: 21px 33px 21px 10px;
      cursor: none;
    }
    .el-dropdown-menu__item:last-child {
      border-bottom: 0px;
    }

    .line {
      position: absolute;
      left: 18px;
      width: 3px;
      height: 12px;
      border-radius: 5px 5px 5px 5px;
      background: rgba(54, 54, 79, 1);
      margin-right: 10px;
      transition: all 0.3s;
    }
    .text {
      color: rgba(113, 117, 145, 1);
      font-size: 16px;
    }

    .is-active {
      .text {
        color: rgba(54, 54, 79, 1);
        font-weight: bold;
      }
    }
  }

  .el-popper__arrow {
    left: 109px !important;
  }
}
</style>
