<template>
  <div class="footer-container user-select-none mobile-wrap">
    <div class="wrap-content">
      <div class="content-box">
        <div class="wrap-box-1 wrap-box">
          <div class="warp-item-1 warp-item">
            <div class="label fontBold">联系热线：</div>
            <p class="text fontRegular">177 2247 6866</p>
          </div>
          <div class="warp-item-2 warp-item">
            <div class="label fontBold">邮箱：</div>
            <p class="text fontRegular">cjx@cloudeffective.cn</p>
          </div>
        </div>
        <div class="wrap-box-2 wrap-box">
          <div class="warp-item-1 warp-item">
            <div class="line fontRegular">
              <span class="fontBold">软件外包</span>：{{ serverInfo }}
            </div>
          </div>
        </div>
        <!-- <div class="wrap-box-4 wrap-box">
          <div class="warp-item-1 warp-item">
            <div class="label">友情链接：</div>
            <p class="text">安服优</p>
          </div>
        </div> -->
        <div class="wrap-box-3 wrap-box">
          <div class="warp-item-1 warp-item">
            <p class="left fontRegular">
              Copyright © 2022-2023 深圳市云效科技有限公司 All Rights Reserved.
            </p>
            <p class="right fontRegular" @click="jumpFilings">
              粤ICP备2023002768号
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// 图标

import { computed, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();
const { dataStore } = proxy.$usePiniaModule();

// logo

// 软件外包服务信息
const serverInfo = `APP定制   |   定制   |   小程序开发   |   公众号开发   |   商城定制   |   企业定制   |   WEB定制   |   网页定制   |   物联网定制`;

// 跳转备案
const jumpFilings = () => {
  const call = () => {
    window.open("https://beian.miit.gov.cn");
  };

  // 记录鼠标操作
  dataStore.setGlobalClickRecord(call);
  call();
};
</script>

<style lang="scss" scoped>
.footer-container {
  &.mobile-wrap {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    .wrap {
      &-content {
        .content-box {
          width: fit-content;
          margin: auto;
          font-size: 12px;
          .wrap-box {
            &-1 {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
            &-2 {
              margin-top: 12px;
              .warp-item {
                &-1 {
                  .line {
                    line-height: 22px;
                    color: rgba(102, 102, 102, 1);
                    span {
                      font-weight: bold;
                      color: rgba(0, 0, 0, 1);
                    }
                  }
                }
              }
            }

            &-3 {
              width: fit-content;
              margin: auto;
              margin-top: 12px;
              .warp-item {
                &-1 {
                  display: flex;
                  flex-direction: column !important;
                  justify-content: center;
                  color: rgba(153, 153, 153, 1);
                  font-size: 12px;
                  .left {
                    text-align: center;
                    margin-bottom: 10px;
                  }
                }
              }
            }

            &-4 {
              margin-top: 12px;
            }

            .warp-item {
              display: flex;
              align-items: center;
              .label {
                font-weight: bold;
              }
              .text {
                transition: opacity 300ms;

                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
