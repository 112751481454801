import { computed } from "vue";
import piniaStore from "@/piniaStore";
import { STORE_NAME_PATH_TRAJECTORY_CODE } from "@/utils/storeAgeTables";
export default function () {
  const proxy = this;
  const { dataStore } = piniaStore;
  const useDataStore = dataStore();

  const isNavigatorClient = computed(() => proxy.$isNavigatorClient());

  // *************************************************************************************
  // 监听鼠标操作 START
  // *************************************************************************************
  //初始操作区域的事件监听
  const initMouseOperateFun = () => {
    const storeCode = proxy.$getStamp16BinaryCode();
    proxy.$setItem(STORE_NAME_PATH_TRAJECTORY_CODE, storeCode);

    //鼠标移动
    window.onmousemove = proxy.$bypass((e) => {
      // e.preventDefault();
      // console.log(window);
      const { innerWidth, innerHeight } = window;
      const { pageY, pageX } = e;

      const stamp = Date.now();
      const params = {
        moveY: pageY,
        moveX: pageX,
        showWidth: innerWidth,
        showHeight: innerHeight,
      };

      // 赋值
      useDataStore.globalStoreMouseOperationRecord.push({
        ...params,
        stamp,
        type: "mousemove",
      });
    }, 10);
  };

  //鼠标点击摁住
  window.onmousedown = (e) => {
    const stamp = Date.now();

    useDataStore.globalStoreMouseOperationRecord.push({
      stamp,
      type: "mousedown",
    });
  };
  // 松开
  window.onmouseup = (e) => {
    // console.log(e);
    const stamp = Date.now();

    useDataStore.globalStoreMouseOperationRecord.push({
      stamp,
      type: "mouseup",
    });

    // PC
    if (!isNavigatorClient.value) {
      const data = proxy.$removeDecontextAttr(
        useDataStore.globalStoreMouseOperationRecord
      );
      proxy.$addMousePtsSux(data);
      useDataStore.globalStoreMouseOperationRecord = [];
    }
  };

  // *************************************************************************************
  // 监听鼠标操作 END
  // *************************************************************************************

  return {
    initMouseOperateFun,
  };
}
